/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { create as n } from "./aaBoundingRect.js";
function t(n) {
  return void 0 !== n.xmin && void 0 !== n.ymin && void 0 !== n.xmax && void 0 !== n.ymax;
}
function i(n) {
  return void 0 !== n.points;
}
function o(n) {
  return void 0 !== n.x && void 0 !== n.y;
}
function e(n) {
  return void 0 !== n.paths;
}
function r(n) {
  return void 0 !== n.rings;
}
function u(n) {
  function t(t, i) {
    return null == t ? i : null == i ? t : n(t, i);
  }
  return t;
}
const l = u(Math.min),
  h = u(Math.max);
function a(n, u) {
  return e(u) ? x(n, u.paths, !1, !1) : r(u) ? x(n, u.rings, !1, !1) : i(u) ? g(n, u.points, !1, !1, !1, !1) : t(u) ? s(n, u) : (o(u) && (n[0] = u.x, n[1] = u.y, n[2] = u.x, n[3] = u.y), n);
}
function c(t) {
  let i, o, e, r;
  for (t.reset(), i = e = 1 / 0, o = r = -1 / 0; t.nextPath();) {
    const n = f(t);
    i = Math.min(n[0], i), e = Math.min(n[1], e), o = Math.max(n[2], o), r = Math.max(n[3], r);
  }
  return n([i, e, o, r]);
}
function f(t) {
  let i, o, e, r;
  for (i = e = 1 / 0, o = r = -1 / 0; t.nextPoint();) i = Math.min(t.x, i), e = Math.min(t.y, e), o = Math.max(t.x, o), r = Math.max(t.y, r);
  return n([i, e, o, r]);
}
function m(n, u) {
  return e(u) ? x(n, u.paths, !0, !1) : r(u) ? x(n, u.rings, !0, !1) : i(u) ? g(n, u.points, !0, !1, !0, !1) : t(u) ? s(n, u, !0, !1, !0, !1) : (o(u) && (n[0] = u.x, n[1] = u.y, n[2] = u.z, n[3] = u.x, n[4] = u.y, n[5] = u.z), n);
}
function x(n, t, i, o) {
  const e = i ? 3 : 2;
  if (!t.length || !t[0].length) return null;
  let r,
    u,
    a,
    c,
    [f, m] = t[0][0],
    [x, s] = t[0][0];
  for (let g = 0; g < t.length; g++) {
    const n = t[g];
    for (let t = 0; t < n.length; t++) {
      const g = n[t],
        [y, d] = g;
      if (f = l(f, y), m = l(m, d), x = h(x, y), s = h(s, d), i && g.length > 2) {
        const n = g[2];
        r = l(r, n), u = h(u, n);
      }
      if (o && g.length > e) {
        const n = g[e];
        a = l(r, n), c = h(u, n);
      }
    }
  }
  return i ? o ? (n[0] = f, n[1] = m, n[2] = r, n[3] = a, n[4] = x, n[5] = s, n[6] = u, n[7] = c, n.length = 8, n) : (n[0] = f, n[1] = m, n[2] = r, n[3] = x, n[4] = s, n[5] = u, n.length = 6, n) : o ? (n[0] = f, n[1] = m, n[2] = a, n[3] = x, n[4] = s, n[5] = c, n.length = 6, n) : (n[0] = f, n[1] = m, n[2] = x, n[3] = s, n.length = 4, n);
}
function s(n, t, i, o, e, r) {
  const u = t.xmin,
    l = t.xmax,
    h = t.ymin,
    a = t.ymax;
  let c = t.zmin,
    f = t.zmax,
    m = t.mmin,
    x = t.mmax;
  return e ? (c = c || 0, f = f || 0, r ? (m = m || 0, x = x || 0, n[0] = u, n[1] = h, n[2] = c, n[3] = m, n[4] = l, n[5] = a, n[6] = f, n[7] = x, n) : (n[0] = u, n[1] = h, n[2] = c, n[3] = l, n[4] = a, n[5] = f, n)) : r ? (m = m || 0, x = x || 0, n[0] = u, n[1] = h, n[2] = m, n[3] = l, n[4] = a, n[5] = x, n) : (n[0] = u, n[1] = h, n[2] = l, n[3] = a, n);
}
function g(n, t, i, o, e, r) {
  const u = i ? 3 : 2,
    a = o && r,
    c = i && e;
  if (!t.length || !t[0].length) return null;
  let f,
    m,
    x,
    s,
    [g, y] = t[0],
    [d, M] = t[0];
  for (let v = 0; v < t.length; v++) {
    const n = t[v],
      [i, o] = n;
    if (g = l(g, i), y = l(y, o), d = h(d, i), M = h(M, o), c && n.length > 2) {
      const t = n[2];
      f = l(f, t), m = h(m, t);
    }
    if (a && n.length > u) {
      const t = n[u];
      x = l(f, t), s = h(m, t);
    }
  }
  return e ? (f = f || 0, m = m || 0, r ? (x = x || 0, s = s || 0, n[0] = g, n[1] = y, n[2] = f, n[3] = x, n[4] = d, n[5] = M, n[6] = m, n[7] = s, n) : (n[0] = g, n[1] = y, n[2] = f, n[3] = d, n[4] = M, n[5] = m, n)) : r ? (x = x || 0, s = s || 0, n[0] = g, n[1] = y, n[2] = x, n[3] = d, n[4] = M, n[5] = s, n) : (n[0] = g, n[1] = y, n[2] = d, n[3] = M, n);
}
export { a as getBoundsXY, m as getBoundsXYZ, c as getCursorBoundsXY, f as getCursorPathBounds, s as getExtentBounds, g as getPointsBounds, x as getRingsOrPathsBounds };