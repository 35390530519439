/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { equals as r } from "../core/arrayUtils.js";
import { clone as e } from "../core/lang.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { subclass as n } from "../core/accessorSupport/decorators/subclass.js";
import { writer as i } from "../core/accessorSupport/decorators/writer.js";
import o from "./Extent.js";
import a from "./Geometry.js";
import l from "./Point.js";
import h from "./SpatialReference.js";
import { polygonCentroid as p } from "./support/centroid.js";
import { polygonContainsPoint as c } from "./support/contains.js";
import { isClockwise as u } from "./support/coordsUtils.js";
import { getPolygonExtent as f } from "./support/extentUtils.js";
import { isSelfIntersecting as m } from "./support/intersectsBase.js";
import { project as g } from "./support/webMercatorUtils.js";
import { updateSupportFromPoint as y } from "./support/zmUtils.js";
var d;
function R(t) {
  return !Array.isArray(t[0]);
}
let x = d = class extends a {
  static fromExtent(t) {
    const r = t.clone().normalize(),
      e = t.spatialReference;
    let s = !1,
      n = !1;
    for (const o of r) o.hasZ && (s = !0), o.hasM && (n = !0);
    const i = {
      rings: r.map(t => {
        const r = [[t.xmin, t.ymin], [t.xmin, t.ymax], [t.xmax, t.ymax], [t.xmax, t.ymin], [t.xmin, t.ymin]];
        if (s && t.hasZ) {
          const e = t.zmin + .5 * (t.zmax - t.zmin);
          for (let t = 0; t < r.length; t++) r[t].push(e);
        }
        if (n && t.hasM) {
          const e = t.mmin + .5 * (t.mmax - t.mmin);
          for (let t = 0; t < r.length; t++) r[t].push(e);
        }
        return r;
      }),
      spatialReference: e
    };
    return s && (i.hasZ = !0), n && (i.hasM = !0), new d(i);
  }
  constructor(...t) {
    super(...t), this.rings = [], this.type = "polygon";
  }
  normalizeCtorArgs(t, r) {
    let e,
      s,
      n = null,
      i = null;
    return t && !Array.isArray(t) ? (n = t.rings ?? null, r || (t.spatialReference ? r = t.spatialReference : t.rings || (r = t)), e = t.hasZ, s = t.hasM) : n = t, n = n || [], r = r || h.WGS84, n.length && null != n[0]?.[0] && "number" == typeof n[0][0] && (n = [n]), i = n[0]?.[0], i && (void 0 === e && void 0 === s ? (e = i.length > 2, s = i.length > 3) : void 0 === e ? e = s ? i.length > 3 : i.length > 2 : void 0 === s && (s = e ? i.length > 3 : i.length > 2)), {
      rings: n,
      spatialReference: r,
      hasZ: e,
      hasM: s
    };
  }
  get cache() {
    return this.commitProperty("rings"), this.commitProperty("hasZ"), this.commitProperty("hasM"), this.commitProperty("spatialReference"), {};
  }
  get centroid() {
    const t = p(this);
    if (!t || isNaN(t[0]) || isNaN(t[1]) || this.hasZ && isNaN(t[2])) return null;
    const r = new l();
    return r.x = t[0], r.y = t[1], r.spatialReference = this.spatialReference, this.hasZ && (r.z = t[2]), r;
  }
  get extent() {
    const {
        spatialReference: t
      } = this,
      r = f(this);
    if (!r) return null;
    const e = new o(r);
    return e.spatialReference = t, e;
  }
  get isSelfIntersecting() {
    return m(this.rings);
  }
  writeRings(t, r) {
    r.rings = e(this.rings);
  }
  addRing(t) {
    if (!t) return;
    const r = this.rings,
      e = r.length;
    if (R(t)) {
      const s = [];
      for (let r = 0, e = t.length; r < e; r++) s[r] = t[r].toArray();
      r[e] = s;
    } else r[e] = t.concat();
    return this.notifyChange("rings"), this;
  }
  clone() {
    const t = new d();
    return t.spatialReference = this.spatialReference, t.rings = e(this.rings), t.hasZ = this.hasZ, t.hasM = this.hasM, t;
  }
  equals(t) {
    if (this === t) return !0;
    if (null == t) return !1;
    const e = this.spatialReference,
      s = t.spatialReference;
    if (null != e != (null != s)) return !1;
    if (null != e && null != s && !e.equals(s)) return !1;
    if (this.rings.length !== t.rings.length) return !1;
    const n = ([t, r, e, s], [n, i, o, a]) => t === n && r === i && (null == e && null == o || e === o) && (null == s && null == a || s === a);
    for (let i = 0; i < this.rings.length; i++) {
      const e = this.rings[i],
        s = t.rings[i];
      if (!r(e, s, n)) return !1;
    }
    return !0;
  }
  contains(t) {
    if (!t) return !1;
    const r = g(t, this.spatialReference);
    return c(this, null != r ? r : t);
  }
  isClockwise(t) {
    const r = R(t) ? t.map(t => this.hasZ ? this.hasM ? [t.x, t.y, t.z, t.m] : [t.x, t.y, t.z] : [t.x, t.y]) : t;
    return u(r);
  }
  getPoint(t, r) {
    if (!this._validateInputs(t, r)) return null;
    const e = this.rings[t][r],
      s = this.hasZ,
      n = this.hasM;
    return s && !n ? new l(e[0], e[1], e[2], void 0, this.spatialReference) : n && !s ? new l(e[0], e[1], void 0, e[2], this.spatialReference) : s && n ? new l(e[0], e[1], e[2], e[3], this.spatialReference) : new l(e[0], e[1], this.spatialReference);
  }
  insertPoint(t, r, e) {
    return this._validateInputs(t, r, !0) ? (y(this, e), Array.isArray(e) || (e = e.toArray()), this.rings[t].splice(r, 0, e), this.notifyChange("rings"), this) : this;
  }
  removePoint(t, r) {
    if (!this._validateInputs(t, r)) return null;
    const e = new l(this.rings[t].splice(r, 1)[0], this.spatialReference);
    return this.notifyChange("rings"), e;
  }
  removeRing(t) {
    if (!this._validateInputs(t, null)) return null;
    const r = this.rings.splice(t, 1)[0],
      e = this.spatialReference,
      s = r.map(t => new l(t, e));
    return this.notifyChange("rings"), s;
  }
  setPoint(t, r, e) {
    return this._validateInputs(t, r) ? (y(this, e), Array.isArray(e) || (e = e.toArray()), this.rings[t][r] = e, this.notifyChange("rings"), this) : this;
  }
  _validateInputs(t, r, e = !1) {
    if (null == t || t < 0 || t >= this.rings.length) return !1;
    if (null != r) {
      const s = this.rings[t];
      if (e && (r < 0 || r > s.length)) return !1;
      if (!e && (r < 0 || r >= s.length)) return !1;
    }
    return !0;
  }
  toJSON(t) {
    return this.write({}, t);
  }
};
t([s({
  readOnly: !0
})], x.prototype, "cache", null), t([s({
  readOnly: !0
})], x.prototype, "centroid", null), t([s({
  readOnly: !0
})], x.prototype, "extent", null), t([s({
  readOnly: !0
})], x.prototype, "isSelfIntersecting", null), t([s({
  type: [[[Number]]],
  json: {
    write: {
      isRequired: !0
    }
  }
})], x.prototype, "rings", void 0), t([i("rings")], x.prototype, "writeRings", null), x = d = t([n("esri.geometry.Polygon")], x), x.prototype.toJSON.isDefaultToJSON = !0;
const j = x;
export { j as default };