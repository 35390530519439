/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { collectPath as t } from "../geometryCursorCollectUtils.js";
import { getLength as n, getMidpoint as e } from "./coordsUtils.js";
function r(t) {
  return t ? t.hasZ ? [t.xmax - t.xmin / 2, t.ymax - t.ymin / 2, t.zmax - t.zmin / 2] : [t.xmax - t.xmin / 2, t.ymax - t.ymin / 2] : null;
}
function l(t) {
  return t ? o(t.rings, t.hasZ ?? !1) : null;
}
function o(t, n) {
  if (!t?.length) return null;
  const e = [],
    r = [],
    l = n ? [1 / 0, -1 / 0, 1 / 0, -1 / 0, 1 / 0, -1 / 0] : [1 / 0, -1 / 0, 1 / 0, -1 / 0];
  for (let o = 0, i = t.length; o < i; o++) {
    const e = u(t[o], n, l);
    e && r.push(e);
  }
  if (r.sort((t, e) => {
    let r = t[2] - e[2];
    return 0 === r && n && (r = t[4] - e[4]), r;
  }), r.length && (e[0] = r[0][0], e[1] = r[0][1], n && (e[2] = r[0][3]), (e[0] < l[0] || e[0] > l[1] || e[1] < l[2] || e[1] > l[3] || n && (e[2] < l[4] || e[2] > l[5])) && (e.length = 0)), !e.length) {
    const r = t[0] && t[0].length ? s(t[0], n) : null;
    if (!r) return null;
    e[0] = r[0], e[1] = r[1], n && r.length > 2 && (e[2] = r[2]);
  }
  return e;
}
function u(t, n, e) {
  let r = 0,
    l = 0,
    o = 0,
    u = 0,
    i = 0;
  const s = t.length ? t[0][0] : 0,
    I = t.length ? t[0][1] : 0,
    h = t.length && n ? t[0][2] : 0;
  for (let f = 0; f < t.length; f++) {
    const c = t[f],
      N = t[(f + 1) % t.length],
      [x, g, a] = c,
      m = x - s,
      P = g - I,
      [T, y, E] = N,
      S = T - s,
      p = y - I,
      z = m * p - S * P;
    if (u += z, r += (m + S) * z, l += (P + p) * z, n && c.length > 2 && N.length > 2) {
      const t = a - h,
        n = E - h,
        e = m * n - S * t;
      o += (t + n) * e, i += e;
    }
    x < e[0] && (e[0] = x), x > e[1] && (e[1] = x), g < e[2] && (e[2] = g), g > e[3] && (e[3] = g), n && (a < e[4] && (e[4] = a), a > e[5] && (e[5] = a));
  }
  if (u > 0 && (u *= -1), i > 0 && (i *= -1), !u) return null;
  u *= .5, i *= .5;
  const c = [r / (6 * u) + s, l / (6 * u) + I, u];
  return n && (e[4] === e[5] || 0 === i ? (c[3] = (e[4] + e[5]) / 2, c[4] = 0) : (c[3] = o / (6 * i) + h, c[4] = i)), c;
}
function i(t, n) {
  let e = 0,
    r = 0,
    l = 0;
  t.nextPoint();
  const o = t.pathSize ? t.x : 0,
    u = t.pathSize ? t.y : 0;
  for (let i = 0; i < t.pathSize; i++) {
    t.seekInPath(i);
    const s = [t.x, t.y];
    t.seekInPath((i + 1) % t.pathSize);
    const I = [t.x, t.y],
      [h, c] = s,
      f = h - o,
      N = c - u,
      [x, g] = I,
      a = x - o,
      m = g - u,
      P = f * m - a * N;
    l += P, e += (f + a) * P, r += (N + m) * P, h < n[0] && (n[0] = h), h > n[1] && (n[1] = h), c < n[2] && (n[2] = c), c > n[3] && (n[3] = c);
  }
  if (l > 0 && (l *= -1), !l) return null;
  l *= .5;
  return [e / (6 * l) + o, r / (6 * l) + u, l];
}
function s(t, r) {
  const l = r ? [0, 0, 0] : [0, 0],
    o = r ? [0, 0, 0] : [0, 0];
  let u = 0,
    i = 0,
    s = 0,
    I = 0;
  for (let h = 0, c = t.length; h < c - 1; h++) {
    const c = t[h],
      f = t[h + 1];
    if (c && f) {
      l[0] = c[0], l[1] = c[1], o[0] = f[0], o[1] = f[1], r && c.length > 2 && f.length > 2 && (l[2] = c[2], o[2] = f[2]);
      const t = n(l, o);
      if (t) {
        u += t;
        const n = e(c, f);
        i += t * n[0], s += t * n[1], r && n.length > 2 && (I += t * n[2]);
      }
    }
  }
  return u > 0 ? r ? [i / u, s / u, I / u] : [i / u, s / u] : t.length ? t[0] : null;
}
function I(n) {
  const {
    hasZ: e,
    totalSize: r
  } = n;
  if (0 === r) return null;
  const l = [],
    o = [],
    i = e ? [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY] : [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];
  for (n.reset(); n.nextPath();) {
    const e = u(t(n), n.hasZ, i);
    e && o.push(e);
  }
  if (o.sort((t, n) => {
    let r = t[2] - n[2];
    return 0 === r && e && (r = t[4] - n[4]), r;
  }), o.length && (l[0] = o[0][0], l[1] = o[0][1], e && (l[2] = o[0][3]), (l[0] < i[0] || l[0] > i[1] || l[1] < i[2] || l[1] > i[3] || e && (l[2] < i[4] || l[2] > i[5])) && (l.length = 0)), !l.length) {
    n.reset(), n.nextPath();
    const t = n.pathSize ? h(n) : null;
    if (!t) return null;
    l[0] = t[0], l[1] = t[1], e && t.length > 2 && (l[2] = t[2]);
  }
  return l;
}
function h(t) {
  const {
      hasZ: r
    } = t,
    l = r ? [0, 0, 0] : [0, 0],
    o = r ? [0, 0, 0] : [0, 0];
  let u = 0,
    i = 0,
    s = 0,
    I = 0;
  if (t.nextPoint()) {
    let h = t.x,
      c = t.y,
      f = t.z;
    for (; t.nextPoint();) {
      const N = t.x,
        x = t.y,
        g = t.z;
      l[0] = h, l[1] = c, o[0] = N, o[1] = x, r && (l[2] = f, o[2] = g);
      const a = n(l, o);
      if (a) {
        u += a;
        const t = e(l, o);
        i += a * t[0], s += a * t[1], r && t.length > 2 && (I += a * t[2]);
      }
      h = N, c = x, f = g;
    }
  }
  return u > 0 ? r ? [i / u, s / u, I / u] : [i / u, s / u] : t.pathSize ? (t.seekPathStart(), t.nextPoint(), [t.x, t.y]) : null;
}
const c = 1e-6;
function f(t) {
  let n = 0;
  for (t.reset(); t.nextPath();) n += t.getCurrentRingArea();
  if (n < c) {
    const n = I(t);
    return n ? [n[0], n[1]] : null;
  }
  const e = [0, 0];
  if (t.reset(), !t.nextPath() || !t.nextPoint()) return null;
  const r = [t.x, t.y];
  for (t.reset(); t.nextPath();) x(e, r, t);
  return e[0] *= 1 / n, e[1] *= 1 / n, e[0] += r[0], e[1] += r[1], e;
}
const N = 1 / 3;
function x(t, n, e) {
  if (!t || !e || e.pathSize < 3) return null;
  e.nextPoint();
  const r = e.x,
    l = e.y;
  e.nextPoint();
  let o,
    u = e.x - r,
    i = e.y - l,
    s = 0,
    I = 0;
  for (; e.nextPoint();) s = e.x - r, I = e.y - l, o = .5 * N * (s * i - I * u), t[0] += o * (u + s), t[1] += o * (i + I), u = s, i = I;
  const h = e.getCurrentRingArea(),
    c = [r, l];
  return c[0] -= n[0], c[1] -= n[1], c[0] *= h, c[1] *= h, t[0] += c[0], t[1] += c[1], t;
}
export { r as extentCentroid, s as lineCentroid, h as lineCentroidCursor, l as polygonCentroid, u as ringCentroid, i as ringCentroidCursorXY, o as ringsCentroid, I as ringsCentroidCursor, f as weightedAreaCentroid };