/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getPointsBounds as n, getRingsOrPathsBounds as i } from "./boundsUtils.js";
function t(n) {
  return void 0 !== n.xmin && void 0 !== n.ymin && void 0 !== n.xmax && void 0 !== n.ymax;
}
function u(n) {
  return void 0 !== n.points;
}
function m(n) {
  return void 0 !== n.x && void 0 !== n.y;
}
function o(n) {
  return void 0 !== n.paths;
}
function r(n) {
  return void 0 !== n.rings;
}
const x = [];
function a(n, i, t, u) {
  return {
    xmin: n,
    ymin: i,
    xmax: t,
    ymax: u
  };
}
function c(n, i, t, u, m, o) {
  return {
    xmin: n,
    ymin: i,
    zmin: t,
    xmax: u,
    ymax: m,
    zmax: o
  };
}
function s(n, i, t, u, m, o) {
  return {
    xmin: n,
    ymin: i,
    mmin: t,
    xmax: u,
    ymax: m,
    mmax: o
  };
}
function e(n, i, t, u, m, o, r, x) {
  return {
    xmin: n,
    ymin: i,
    zmin: t,
    mmin: u,
    xmax: m,
    ymax: o,
    zmax: r,
    mmax: x
  };
}
function f(n, i = !1, t = !1) {
  return i ? t ? e(n[0], n[1], n[2], n[3], n[4], n[5], n[6], n[7]) : c(n[0], n[1], n[2], n[3], n[4], n[5]) : t ? s(n[0], n[1], n[2], n[3], n[4], n[5]) : a(n[0], n[1], n[2], n[3]);
}
function l(n) {
  return n ? t(n) ? n : m(n) ? d(n) : r(n) ? v(n) : o(n) ? h(n) : u(n) ? y(n) : null : null;
}
function y(i) {
  const {
    hasZ: t,
    hasM: u,
    points: m
  } = i;
  return f(n(x, m, t ?? !1, u ?? !1), t, u);
}
function d(n) {
  const {
      x: i,
      y: t,
      z: u,
      m
    } = n,
    o = null != m;
  return null != u ? o ? e(i, t, u, m, i, t, u, m) : c(i, t, u, i, t, u) : o ? s(i, t, m, i, t, m) : a(i, t, i, t);
}
function v(n) {
  const {
      hasZ: t,
      hasM: u,
      rings: m
    } = n,
    o = i(x, m, t ?? !1, u ?? !1);
  return o ? f(o, t, u) : null;
}
function h(n) {
  const {
      hasZ: t,
      hasM: u,
      paths: m
    } = n,
    o = i(x, m, t ?? !1, u ?? !1);
  return o ? f(o, t, u) : null;
}
export { l as getGeometryExtent, y as getMultipointExtent, d as getPointExtent, v as getPolygonExtent, h as getPolylineExtent };